import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import gtm from '~/utils/gtm';
import CookieConsent from 'vanilla-cookieconsent';
import {
  CookieConsentCategory,
  isCookieConsentCategory,
} from '~/components/organisms/CookieBar';

@Module({
  name: 'Visitor',
  stateFactory: true,
  namespaced: true,
})
export default class Visitor extends AbstractModule {
  protected gtmLoaded: boolean = false;

  @Action({ rawError: true })
  public async handleCookieChange(
    data: CookieConsent.CookieValue
  ): Promise<void> {
    if (
      data.categories.length > 0 &&
      data.categories.find(
        (cat) =>
          isCookieConsentCategory(cat) &&
          [
            CookieConsentCategory.ANALYTICS,
            CookieConsentCategory.MARKETING,
            CookieConsentCategory.PERSONALISED,
          ].includes(cat)
      )
    ) {
      return this.loadGTM();
    } else if (this.gtmLoaded) {
      window.location.reload();
    }
    return Promise.resolve();
  }

  @Action({ rawError: true })
  public loadGTM(): Promise<void> {
    if (!this.gtmLoaded) {
      gtm();
      // TODO: Remove this after ELIXIR is done implementing the new GTM and use the proper method (env)
      gtm('GTM-TGGC8ZXG');
      this.setGTMLoaded(true);
    }
    return Promise.resolve();
  }

  @Mutation
  protected setGTMLoaded(state: boolean) {
    this.gtmLoaded = state;
  }
}
